/*
 * @Author: anshengtyp
 * @Date: 2023-10-26 17:37:18
 * @LastEditTime: 2023-10-30 11:36:31
 * @LastEditors: “@ma-sining” “598138645@qq.com”
 * @Description: 
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/index.vue"),
  },
  {
    path: "/appMall",
    name: "AppMall",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/appMall/index.vue"),
  },
  // 产品服务
  {
    path: "/productService",
    name: "productService",
    component: () => import("../views/productService"),
  },
  // 行业案例
  {
    path: "/industry",
    name: "industry",
    component: () => import("../views/industry"),
  },
  // 关于我们
  {
    path: "/own",
    name: "own",
    component: () => import("../views/learnTX/own"),
  },
  // 关于我们
  {
    path: "/help",
    name: "help",
    component: () => import("../views/learnTX/help"),
  },
  // 关于我们
  {
    path: "/detail",
    name: "detail",
    component: () => import("../views/detail"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
