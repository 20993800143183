import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    footerIndex: "/",
  },
  getters: {},
  mutations: {
    SET_FOOTER_INDEX(state, footerIndex) {
      state.footerIndex = footerIndex;
    },
  },
  actions: {},
  modules: {},
});
