/*
 * @Author: anshengtyp
 * @Date: 2023-10-27 15:25:44
 * @LastEditTime: 2023-11-07 08:31:01
 * @LastEditors: anshengtyp
 * @Description: 
 */
export default {
  inserted(el) {
    let isNoScroll = false;
    let top = 0;

    function handleIntersection(entries) {
      entries.forEach((entry) => {
        // 向下滚动执行动画
        if (entry.isIntersecting || isNoScroll) {
          el.classList.add('view-animation-active');
          el.classList.remove('view-animation');
        }
        // 如果不在视口同时向上滚动
        if (!entry.isIntersecting && !isNoScroll) {
          el.classList.add('view-animation');
          el.classList.remove('view-animation-active');
        }
      });
    }

    const options = {
      root: null,
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(el);

    window.addEventListener("scroll", (e) => {
      if (e.target.scrollTop > top) {
        // 向下滚动
        isNoScroll = true;
      } else {
        isNoScroll = false;
      }
      top = e.target.scrollTop;
    }, true);
  }
}
