<!--
 * @Author: anshengtyp
 * @Date: 2023-10-25 16:18:20
 * @LastEditTime: 2023-10-30 16:32:43
 * @LastEditors: anshengtyp
 * @Description: 
-->
<template>
  <div id="app">
    <el-container>
      <el-header class="header">
        <Header></Header>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import Footer from "./views/footer/index.vue";
import Header from "./views/header/index.vue";
export default {
  components: {
    Footer,
    Header,
  },
  data() {
    return {};
  },

  mounted() {

  },

  methods: {},
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .header {
    position: fixed;
    left: 0;
    top: 0;
    height: 82px !important;
    padding: 0px !important;
    z-index: 10;
  }

  .el-main {
    padding: 0px !important;
    min-height: 1000px;
  }

  .el-footer {
    padding: 0 !important;
  }
}
</style>
