<!--
 * @Author: anshengtyp
 * @Date: 2023-10-25 16:57:23
 * @LastEditTime: 2024-06-26 11:07:46
 * @LastEditors: anshengtyp 1815634838@qq.com
 * @Description:  底部
-->
<template>
  <div class="footer">
    <div class="footer-font">
      <div class="fonter-box">
        <span class="footer-box-lj">相关链接</span>
        <div class="footer-box-img">
          <img src="@/assets/footer/zrzyt.png" alt="" @click="onOpenTabClick('http://zrzyt.hunan.gov.cn/')" />
          <img src="@/assets/footer/tdt.png" alt="" @click="
            onOpenTabClick(
              'https://hunan.tianditu.gov.cn/TDTHN/portal/index.html'
            )
            " />
        </div>
        <div class="footer-box-list">
          <div v-for="(item, index) in linkDatas" :key="index">
            <span class="title">{{ item.title }}</span>
            <template v-for="el in item.links">
              <div class="link" :key="el.name" @click="skip(el.routerUrl, el.activeIndex)">
                {{ el.name }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="ewm">
      <div class="ewm-box">
        <span class="ewm-box-download">下载我们</span>
        <img class="qr" src="@/assets/footer/qr.png" alt="" />
        <div class="opinion">
          <img class="opinion-img" src="@/assets/footer/idea.png" alt="" />
          <span>意见反馈</span>
        </div>
      </div>
    </div>
    <div class="footer-info">
      <div class="info-box">
        <div class="info">
          <span>版权所有：湖南省第三测绘院</span>
          <span>意见反馈：hnpgc@hnpgc.com</span>
        </div>
        <div class="record">
          <img class="record-img" src="@/assets/footer/goan.png" alt="" />
          <span>湘ICP备05000257号-6</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      linkDatas: [
        {
          title: "产品功能",

          links: [
            {
              name: "地图导航浏览",
              routerUrl: "/productService#mapNavigation",
              activeIndex: "/productService",
            },
            {
              name: "任务管理",
              routerUrl: "/productService#taskManagement",
              activeIndex: "/productService",
            },
            {
              name: "用户管理",
              routerUrl: "/productService#userManagement",
              activeIndex: "/productService",
            },
            {
              name: "智能化拍照举证",
              routerUrl: "/productService#intelligentPhotoProof",
              activeIndex: "/productService",
            },
            {
              name: "产品六大特性",
              routerUrl: "/productService#peculiarity",
              activeIndex: "/productService",
            },
          ],
        },

        {
          title: "行业案例",

          links: [
            {
              name: "增加耕地实地举证",
              routerUrl: "/industry#plowlandProof",
              activeIndex: "/industry",
            },
            {
              name: "违法查处实地举证",
              routerUrl: "/industry#breakLawProof",
              activeIndex: "/industry",
            },
            {
              name: "三长巡查",
              routerUrl: "/industry#roundsInspect",
              activeIndex: "/industry",
            },
            {
              name: "土地供应净地确认",
              routerUrl: "/industry#landSupplyVerify",
              activeIndex: "/industry",
            },
            {
              name: "农村住宅现场核实确认",
              routerUrl: "/industry#dwellingVerify",
              activeIndex: "/industry",
            },
          ],
        },
        {
          title: "应用商城",
          links: [
            {
              name: "会员服务",
              routerUrl: "/appMall#vipServices",
              activeIndex: "/appMall",
            },
            {
              name: "模板超市",
              routerUrl: "/appMall#templateSupers",
              activeIndex: "/appMall",
            },
            {
              name: "外接设备",
              routerUrl: "/appMall#externalEquipments",
              activeIndex: "/appMall",
            },
          ],
        },
        {
          title: "了解天巡",
          links: [
            {
              name: "动态公告",
              routerUrl: "/#carouselHome",
              activeIndex: "/",
            },
            {
              name: "帮助中心",
              routerUrl: "/help",
              activeIndex: "/help",
            },
            {
              name: "关于我们",
              routerUrl: "/own",
              activeIndex: "/own",
            },
          ],
        },
      ],
    };
  },

  mounted() { },

  methods: {
    skip(path, activeIndex) {
      this.$store.commit("SET_FOOTER_INDEX", activeIndex);
      this.$router.push({ path }).catch((err) => err);
    },
    // 跳转
    onOpenTabClick(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  width: 1920px;
  height: 858px;
  background: url("../../assets/footer/bg.png") no-repeat;
  background-size: 1920px 858px;

  .footer-font {
    position: absolute;
    height: 562px;
    width: 938px;
    top: 99px;
    left: 255px;
    text-align: left;

    .fonter-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      .footer-box-lj {
        color: #ffffff;
        font-size: 24px;
        font-weight: 700;
        font-family: "思源黑体 CN";
      }

      .footer-box-img {
        display: flex;
        justify-content: space-between;

        img {
          width: 454px;
          height: 142px;
          cursor: pointer;
        }
      }

      .footer-box-list {
        display: flex;
        justify-content: space-between;
        width: 938px;
        height: 292px;

        .title {
          color: #ffffff;
          font-size: 24px;
          font-weight: 700;
          font-family: "思源黑体 CN";
        }

        .link {
          margin-top: 32px;
          color: #bcc5cc;
          font-size: 16px;
          font-weight: 400;
          font-family: "思源黑体 CN";
        }

        .link:hover {
          cursor: pointer;
          text-decoration: underline;
          text-decoration-color: #bcc5cc;
        }
      }
    }
  }

  .ewm {
    position: absolute;
    right: 257px;
    top: 369px;
    width: 178px;
    height: 311px;

    .ewm-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      height: 100%;

      .ewm-box-download {
        color: #ffffff;
        font-size: 24px;
        font-weight: 700;
        font-family: "思源黑体 CN";
      }

      .qr {
        width: 178px;
        height: 178px;
      }

      .opinion {
        width: 178px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00000033;
        cursor: pointer;

        .opinion-img {
          width: 24px;
          height: 27.31px;
          margin-right: 8px;
        }

        span {
          margin-left: 8px;
          color: #ffffff;
          font-size: 20px;
          font-weight: 400;
          font-family: "思源黑体 CN";
          line-height: 36px;
        }
      }
    }
  }

  .footer-info {
    position: absolute;
    width: 1406px;
    height: 108px;
    bottom: 0;
    left: 256px;
    border-top: 0.5px solid rgba(179, 179, 179, 0.2);

    .info-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 473px;
        height: 36px;
        margin: 0 auto;

        span {
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
          font-family: "思源黑体 CN";
          line-height: 36px;
        }
      }

      .record {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #b3b3b3;
        font-size: 16px;
        font-weight: 400;
        font-family: "思源黑体 CN";
        line-height: 36px;

        .record-img {
          width: 39px;
          height: 40px;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
