<!--
 * @Author: anshengtyp
 * @Date: 2023-10-30 09:14:22
 * @LastEditTime: 2023-11-06 15:31:24
 * @LastEditors: anshengtyp
 * @Description: 手风琴组件
-->
<template>
  <div>
    <ul class="list">
      <li v-for="(item, index) in switchData" :key="index" :class="[
        index === checkIndex ? 'checkBox' : '',
        index === switchData.length - 1 ? 'noBorder' : '',
        index === 0 ? 'left-radius' : '',
        index === switchData.length - 1 ? 'right-radius' : '']" @mouseenter="enter(index)">
        <div class="bg">
          <span :class="checkIndex === index ? 'activeTitle' : 'title'">{{ item.title }}</span>
          <span class="desc" v-show="checkIndex === index">{{ item.description }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    switchData: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      checkIndex: 0
    };
  },

  mounted() {
  },

  methods: {
    enter(index) {
      this.checkIndex = index;
    }
  },
};
</script>

<style lang="scss" scoped>
.list {
  width: 1408px;
  height: 400px;
  display: flex;
  flex-wrap: nowrap;
  background: url('../../assets/images/关于我们1.png') no-repeat center/cover;
  justify-content: space-between;
  border-radius: 30px;
}

.list li {
  height: 100%;
  width: 240px;
  font-size: 30px;
  border-right: 1px solid white;
  cursor: pointer;
  transition: all 0.3s linear;

  .bg {
    width: 100%;
    height: 100%;
    position: relative;

    .desc {
      position: absolute;
      text-align: left;
      width: 300px;
      top: 170px;
      left: 60px;
      font-size: 24px;
      padding-right: 50px;
    }
  }
}

.checkBox {
  background: url('../../assets/images/关于我们2.png') no-repeat center/cover;
  width: 480px !important;
  overflow: hidden;
}

.notCheckBox {
  width: 230px;
}

.noBorder {
  border: none !important;
}

// 添加左边圆角
.left-radius {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

// 添加右边圆角
.right-radius {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}


.activeTitle {
  display: inline-block;
  margin-left: -180px;
  margin-top: 68px;
  font-size: 48px;
  position: relative;
  font-weight: bold;

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    background-color: #0D85DE;
    width: 50px;
    height: 8px;
    border-radius: 10px;
    top: 70px;
    left: 5px;
  }
}

.title {
  display: inline-block;
  margin-top: 250px;
  margin-left: -40px;
  font-size: 36px;
  font-weight: 500;
  position: relative;

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    background-color: #0D85DE;
    width: 50px;
    height: 8px;
    border-radius: 10px;
    top: 70px;
    left: 5px;
  }
}
</style>