<template>
  <div class="module-title">
    <span class="title">{{ title }}</span>
    <span class="des" v-if="des != ''">{{ des }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    des: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.module-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    color: #333333;
    font-size: 56px;
    font-weight: 700;
    font-family: "思源黑体 CN";
  }
  .des {
    width: 1400px;
    margin-top: 5px;
    color: #333333;
    font-size: 28px;
    font-weight: 400;
    font-family: "思源黑体 CN";
  }
}
</style>
