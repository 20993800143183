import Vue from "vue";
// 默认该文件夹文件自动注册全局组件
// require.context 是webpack的一个api
const req = require.context("./", true, /\.vue$/);
// 全局注册
req.keys().forEach((element) => {
  const componentConfig = req(element);
  const name = element.replace(/(\.\/)|(\.vue)/gi, "");
  const componentName = name.split("/")[0];
  // 全局注册组件
  Vue.component(
    componentName,
    // 如果这个组件选项是通过 `export default` 导出的，
    // 那么就会优先使用 `.default`，否则回退到使用模块的根。
    componentConfig.default || componentConfig
  );
});
