/*
 * @Author: anshengtyp
 * @Date: 2023-10-25 16:18:20
 * @LastEditTime: 2023-11-06 17:22:37
 * @LastEditors: anshengtyp
 * @Description:
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style/reset.scss"; //  重制默认样式
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./utils/flexible";
// 导入需要的组件
import { NoticeBar, Swipe, SwipeItem } from "vant";
import directive from "./directive/index";
import { fontLength } from "./utils/common";
import "@/components/index.js";
import VueAwesomeSwiper from "vue-awesome-swiper";
import fixedToolbar from "../src/components/fixedToolbar";
import "swiper/dist/css/swiper.css";

function px2rem(px) {
  if (/%/gi.test(px)) {
    // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
    return px;
  } else {
    return parseFloat(px) / 192 + "rem";
  }
}

Vue.component('fixedToolbar', fixedToolbar)
// 注册组件
Vue.use(NoticeBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VueAwesomeSwiper);
Vue.use(ElementUI);
Vue.use(directive);

// 全局挂载
Vue.prototype.$px2rem = px2rem; // 放到全局
Vue.config.productionTip = false;
Vue.prototype.fontLength = fontLength;

let vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");


// 滚动条回到顶部
router.afterEach((to) => {
  if (to.fullPath.indexOf('#') === -1) {
    vm.$el.scrollTop = 0;
  }
});