/*
 * @Author: anshengtyp
 * @Date: 2023-10-27 14:38:20
 * @LastEditTime: 2023-10-27 14:46:44
 * @LastEditors: anshengtyp
 * @Description: 
 */
import viewAn from './viewAn/viewAn'


const install = (Vue) => {
  Vue.directive('viewAn', viewAn);
}

export default install
