<!--
 * @Author: anshengtyp
 * @Date: 2023-11-02 08:48:50
 * @LastEditTime: 2023-11-02 09:15:32
 * @LastEditors: anshengtyp
 * @Description: 固定工具栏
-->
<template>
  <div class="floatWindow" :style="{ width: width, height: height }">
    <div v-if="height === '260px'" class="go commonFixed">
      <img src="../../assets/images/17切换@2x.png" @click="switchPage" alt="17切换" @mouseenter="mkEnter"
        @mouseleave="mkLeave">
      <div class="swichMK" @mouseenter="mkEnter" @mouseleave="mkLeave">
        点击切换页面
      </div>
    </div>
    <div class="QRCode commonFixed">
      <img src="../../assets/images/二维码Fixed.png" alt="二维码" @mouseenter="qrEnter" @mouseleave="qrLeave">
      <!--  二维码展示框 -->
      <div class="QRCode-modal" @mouseenter="qrEnter" @mouseleave="qrLeave">
        <span>扫码下载天巡</span>
        <img src="../../assets/images/二维码@2x.png" alt="二维码">
      </div>
    </div>
    <div class="toTop commonFixed">
      <img src="../../assets/images/回到顶部@2x.png" alt="回到顶部" @click="backTop" @mouseenter="topEnter"
        @mouseleave="topLeave">
      <div class="backTop" @mouseenter="topEnter" @mouseleave="topLeave">
        点击返回顶部
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fixedToolbar',
  props: {
    AnchorList: {
      type: Array,
      default: () => []
    },
    width: {
      type: String,
      default: '80px'
    },
    height: {
      type: String,
      default: '260px'
    }
  },
  data() {
    return {
      currentAnchor: '' // 当前锚点
    };
  },

  mounted() {
    this.currentAnchor = this.AnchorList[0];
    document.addEventListener('scroll', this.scrollHandler, true);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollHandler, true);
  },

  methods: {
    scrollHandler() {
      const options = {
        root: null,  // 使用默认的根元素（视窗）
        threshold: 0.7,  //元素可见度超过 70% 时触发回调
      };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((item) => {
          if (item.isIntersecting) {
            this.currentAnchor = item.target.id;
          }
        })
      }, options);
      this.AnchorList.forEach(item => {
        if (document.getElementById(item)) {
          observer.observe(document.getElementById(item));
        }
      })
    },
    // 切换页面
    switchPage() {
      const index = this.AnchorList.findIndex(item => item === this.currentAnchor);
      if (index <= this.AnchorList.length - 2) {
        this.currentAnchor = this.AnchorList[index + 1];
      } else {
        this.currentAnchor = this.AnchorList[0]; // 回到顶部
      }
      const el = document.getElementById(this.currentAnchor);
      el.scrollIntoView({ behavior: 'smooth' });
    },
    qrEnter() {
      const qrCode = document.querySelector('.QRCode-modal');
      qrCode.style.display = 'flex';
    },
    qrLeave() {
      const qrCode = document.querySelector('.QRCode-modal');
      qrCode.style.display = 'none';
    },
    mkEnter() {
      const qrCode = document.querySelector('.swichMK');
      qrCode.style.display = 'flex';
    },
    mkLeave() {
      const qrCode = document.querySelector('.swichMK');
      qrCode.style.display = 'none';
    },
    topEnter() {
      const qrCode = document.querySelector('.backTop');
      qrCode.style.display = 'flex';
    },
    topLeave() {
      const qrCode = document.querySelector('.backTop');
      qrCode.style.display = 'none';
    },
    backTop() {
      const el = document.getElementById(this.AnchorList[0]);
      el.scrollIntoView({ behavior: 'smooth' });
    }
  },
};
</script>

<style lang="scss" scoped>
.floatWindow {
  position: fixed;
  right: 50px;
  top: 60%;
  // width: 80px;
  // height: 260px;
  border: 2px solid #ffffff;
  background: linear-gradient(270deg, #ffffff73 0%, #ffffff61 55.00000000000001%, #ffffff73 100%);
  backdrop-filter: blur(10px);
  border-radius: 50px;
  z-index: 99999;
  display: flex;
  flex-direction: column;

  .go,
  .QRCode {
    border-bottom: 3px solid #3e4143;
    margin: 0 20px;

    >img {
      width: 28px;
      height: 28px;
      object-fit: cover;
    }
  }

  .go>img:hover {
    content: ' ';
    display: inline-block;
  }

  .toTop {
    >img {
      width: 36px;
      height: 36px;
      object-fit: cover;
    }
  }
}

.commonFixed {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.QRCode-modal {
  width: 269px;
  height: 310px;
  background: #ffffff;
  position: absolute;
  right: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  display: none;

  &::before {
    content: ' ';
    display: inline-block;
    width: 0px;
    height: 0px;
    border-top: 20px solid transparent;
    border-right: 20px solid transparent;
    border-left: 20px solid #ffffff;
    border-bottom: 20px solid transparent;
    position: absolute;
    right: -38px;
    top: 50%;
    transform: translateY(-50%);
  }

  >span {
    font-size: 20px;
    margin-bottom: 20px;
    display: inline-block;
    font-weight: bold;
  }

  >img {
    width: 207px;
    height: 202px;
    object-fit: cover;
  }
}

.swichMK,
.backTop {
  width: 191px;
  height: 56px;
  border-radius: 8px;
  position: absolute;
  right: 70px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  display: none;

  &::before {
    content: " ";
    display: inline-block;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-left: 10px solid #ffffff;
    border-bottom: 10px solid transparent;
    position: absolute;
    right: -18px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>