<!--
 * @Author: anshengtyp
 * @Date: 2023-10-25 16:57:23
 * @LastEditTime: 2023-11-06 15:55:07
 * @LastEditors: anshengtyp
 * @Description:  顶部
-->
<template>
  <div class="header">
    <div class="icon"></div>
    <div class="menu">
      <el-menu :default-active="activeIndex" mode="horizontal" :router="true" @select="handleSelect">
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/productService">产品服务</el-menu-item>
        <el-menu-item index="/industry">行业案例</el-menu-item>
        <el-menu-item index="/appMall">应用商城</el-menu-item>
        <el-menu-item index="/own">关于我们</el-menu-item>
        <el-menu-item index="/help">帮助中心</el-menu-item>
      </el-menu>
    </div>
    <!-- 二维码 -->
    <div class="QRCode" title="二维码">
      <div class="QR" @mouseenter="qrInter" @mouseleave="qrLeave"></div>
      <!-- 二维码弹框 -->
      <div class="QRModal" @mouseenter="qrInter" @mouseleave="qrLeave">
        <img src="../../assets/footer/qr.png" alt="二维码" />
      </div>
    </div>
    <div class="others">开放平台</div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      activeIndex: "/",
    };
  },

  computed: {
    footerIndex() {
      return this.$store.state.footerIndex;
    },
  },
  mounted() {
    let router = window.location.href.split("/").at(-1);
    if (router.indexOf('#') > -1) {
      router = router.split('#')[0]
    }
    this.activeIndex = "/" + router
    if (this.activeIndex === '/help') {
      this.changeCss();
    }
    window.addEventListener("scroll", this.handlerScroll, true);
  },

  methods: {
    handlerScroll(e) {
      let befortop = 0;
      let aftertop = e.target.scrollTop;
      if (aftertop - befortop > 0) {
        // 获取全局自定义变量的值
        this.changeCss();
      } else if (aftertop === 0 && this.activeIndex !== '/help') {
        // 到顶部了
        this.cleaerCss();
      }
      befortop = aftertop;
    },
    // 改变顶部样式
    changeCss() {
      const root = document.documentElement;
      root.style.setProperty('--header-color', 'black');
      root.style.setProperty('--header-bgColor', 'rgba(182, 198, 221, 0.5)');
      root.style.setProperty('--header-filter', 'blur(5px)');
      root.style.setProperty('--header-QR', "url('./images/二维码header_active.png')");
    },
    // 重置顶部样式
    cleaerCss() {
      const root = document.documentElement;
      root.style.setProperty('--header-color', '#b6c6dd');
      root.style.setProperty('--header-bgColor', 'rgba(182, 198, 221, 0.08)');
      root.style.setProperty('--header-filter', 'none');
      root.style.setProperty('--header-QR', "url('./images/二维码.png')");
    },
    handleSelect(key) {
      this.activeIndex = key;
    },
    // 鼠标移入
    qrInter() {
      const el = document.querySelector(".QRModal");
      el.style.display = "block";
    },
    qrLeave() {
      const el = document.querySelector(".QRModal");
      el.style.display = "none";
    },
  },
  watch: {
    footerIndex(newVal) {
      this.activeIndex = newVal;
    },
    $route(to) {
      // 路由切换改变顶部样式
      if (to.path === '/help') {
        this.changeCss();
      } else {
        this.cleaerCss();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 100%;
  background-color: var(--header-bgColor);
  position: relative;
  backdrop-filter: var(--header-filter);
  transition: all 0.3s linear;

  .icon {
    width: 147px;
    height: 47px;
    font-size: 36px;
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../assets/images/天巡LOGO.png") no-repeat;
  }

  .menu {
    height: 100%;
    position: absolute;
    right: 270px;

    .el-menu {
      height: 100%;
      background-color: transparent;
      border-bottom: 0px;

      .el-menu-item {
        height: 100%;
        line-height: 80px;
        border-bottom: none;
        color: var(--header-color);
        font-size: 20px;

        &:hover {
          background-color: transparent;
        }
      }

      .is-active {
        background-color: transparent;
        color: #0d85de;
        border-bottom: none;
      }

      .el-submenu {
        height: 100%;

        .active {
          color: #0d85de;
        }

        ::v-deep .el-submenu__title {
          height: 100% !important;
          line-height: 80px;
          border-bottom: none;
          color: var(--header-color);
          font-size: 20px;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.el-menu--horizontal {
  .el-menu {
    .el-menu-item {
      background-color: #222222;
      color: white;
      text-align: center;
      margin: 10px;
      height: 30px;
      line-height: 30px;

      &:hover {
        color: #0d85de;
        background: linear-gradient(90deg, #0d85de26 0%, #0d85de00 100%);
        border-radius: 2px;
      }
    }

    .is-active {
      background-color: #222222;
      color: #0d85de !important;
    }

    a {
      color: white;
      text-decoration: none;
    }

    .router-link-active {
      color: #0d85de !important;
    }
  }
}

.QRCode,
.others {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.QRCode {
  width: 22px;
  height: 22px;
  right: 220px;
  cursor: pointer;

  >.QR {
    width: 100%;
    height: 100%;
    background: var(--header-QR);
    background-size: cover;
  }
}

.others {
  right: 50px;
  width: 120px;
  height: 38px;
  border-radius: 60px;
  border: 2px solid var(--header-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  color: var(--header-color);
}

.QRModal {
  position: absolute;
  left: -90px;
  top: 40px;
  width: 200px;
  height: 200px;
  line-height: 200px;
  text-align: center;
  background: white;
  border-radius: 8px;
  transition: all 0.5s linear;
  display: none;

  &::before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
  }
}

// 鼠标下滑修改菜单图标颜色
::v-deep .el-submenu__title i {
  color: var(--header-color);
}
</style>
